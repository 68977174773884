import React, { useEffect, useState, useRef } from "react";
import { usePopper } from 'react-popper';
import "../styles/popper.css";

import * as s from "../styles/globalStyles";
import "../styles/style.css";

import { 
    Twitter, 
    MessageCircle

 } from 'react-feather';

 import { Icon } from '@iconify/react';

export default function PopperSocial() {

const ref = React.createRef();
const [show, setShow] = useState(false);
const [referenceElement, setReferenceElement] = useState(null);
const [popperElement, setPopperElement] = useState(null);
const [arrowElement, setArrowElement] = useState(null);
const { styles, attributes } = usePopper(referenceElement, popperElement, {
  modifiers: [
    { name: "arrow", options: { element: arrowElement } },
    { name: "offset", options: { offset: [-35, 18] } }
  ]
});

const handleClickOutside = event => {
  if (ref.current && !ref.current.contains(event.target)) {
    setShow(false);
  }
};

useEffect(() => {
  document.addEventListener("click", handleClickOutside);
  return () => {
    document.removeEventListener("unhandledRejection", handleClickOutside);
  };
});

const toggle = () => setShow(prev => !prev);

return (                          
        <div ref={ref} className="container">

            <div
            className="social-menu-button"
            ref={setReferenceElement}
            onClick={toggle}
            >
              <p>Community</p>
            </div> 

            {show && (
            <div
                className="social-menu-link-container"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
            >
                <a className="nav-link" target="_blank" href="https://twitter.com/crgb_nft">
                <div className="nav-link-text"> Twitter <Twitter size={16}/> </div>
                </a>

                <a className="nav-link" target="_blank" href="https://discord.gg/T7NUaM6c">
                <div className="nav-link-text"> Discord <MessageCircle size={16}/></div>
                </a>

              </div>
            )}
    </div>
);
}
