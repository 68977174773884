import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { HexColorPicker, HexColorInput } from "react-colorful";

import "./styles/style.css";

import PopperDes from './components/PopperDes';
import PopperName from './components/PopperName';
import PopperSocial from './components/PopperSocial';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


function App() {
  const dispatch = useDispatch(); 
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [colorName, setColorName] = useState(``);
  const [name, setName] = useState(``);
  const [logo, setLogo] = useState (`cRGB`)
  const [color, setColor] = useState("#EAEAEA");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


/*
  const addTokenToWallet= () => {
    const tokenDecimals = 0;
    const tokenImage = 'https://www.conreco.com.ar/abuelas-plaza-mayo/output-nft/hidden.png';

    try {
      window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
              type: 'ERC20', // Initially only supports ERC20, but eventually more!
              options: {
                  address: CONFIG.CONTRACT_ADDRESS, // The address that the token is at.
                  symbol: CONFIG.SYMBOL, // A ticker symbol or shorthand, up to 5 chars.
                  decimals: tokenDecimals, // The number of decimals in the token
                  image: tokenImage, // A string url of the token logo
              }
          }
        }).then(() => {
            console.log('Thanks for your interest!');
        }).catch(() => {
            console.log('Your loss!');
        });


    } catch (error) {
        console.log(error);
    }
  }

*/

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.SYMBOL} #${hexToTokenId(color)}`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mint( hexToTokenId(color),''+ colorName +'')

      .call({ 
        from: blockchain.account,
        value: totalCostWei,
        },
        function(err){
          if(err){
            console.log(err);
            if (err.message.includes('token already minted'))
            setFeedback("Token is already minted");
            setClaimingNft(false);

            if (err.message.includes('minimum'))
            setFeedback("Color name is too short!");
            setClaimingNft(false);

            if (err.message.includes('maximum'))
            setFeedback("Color name is too long!");
            setClaimingNft(false);

            if (err.message.includes('insufficient'))
            setFeedback("Insufficient funds, you need more ETH!");
            setClaimingNft(false);


          }else{
      blockchain.smartContract.methods
      .mint( hexToTokenId(color),''+ colorName +'')  

      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Something went wrong, please refresh and try again");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congrats 🥳 cRGB #${hexToTokenId(color)} is yours!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    }});};
  

  const handleNameChange = event => {
    const newName = event.target.value.replace(
      /^\"|\/|\'|\?|\&|\\|\"|\„$/g,
      ""
    );
    setName(newName);
  };

const nameCalls = e => {
    handleNameChange(e)
    setColorName(e.target.value)
    setLogo(e.target.value.replace(
      /^\"|\/|\'|\?|\&|\\|\"|\„$/g,
      ""
    ))
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


function hexToTokenId(hex) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return (r << 16) + (g << 8) + (b);
}

function hexToRgb(hex) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `(`+ (r) + `,` + (g) + `,` + (b) + `)`;
}

function rgbToHex(r, g, b) {
  return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}



return (
    <s.Screen
    style={{ backgroundColor: color }} >
    <s.Container
        flex={1}
        ai={"center"}
        jc={"flex-start"}>
        <s.DesktopNavWrapper>

                <s.StyledLogo alt={"logo"} src={"/config/images/logo.png"} />

                <s.StatsWrapper>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? ( 
                <>
                </>
                ) : (
                  <>
                   <s.Stats
                  style={{textAlign:"center"}}>
                 {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                </s.Stats>
                  </>
                )}

              </s.StatsWrapper>


        
        <s.Container
        flex={1}
        fd={"row"}
        ai={"flex-end"}
        jc={"flex-end"}>
              <s.MenuWrapper>

                <s.MenuSelected>Mint</s.MenuSelected> 

                <a className="nav-link" target="_blank" href="http://story.crgb.io">
                <s.MenuLink>Story</s.MenuLink> 
                </a>

                <a className="nav-link" target="_blank" href={CONFIG.MARKETPLACE_LINK}>
                <s.MenuLink>Market</s.MenuLink> 
                </a>

                <s.PopperWrapper> <PopperSocial/> </s.PopperWrapper> 
              
              </s.MenuWrapper>

              {/* <s.Network><FileText className="feather-contract"/></s.Network> */}
        </s.Container>
        </s.DesktopNavWrapper>

       

        <s.Brand 
          alt={"logo"}
          style={{
           mixBlendMode: "difference"}
           }>
          "{logo}"
        </s.Brand> 

        <s.ResponsiveWrapper>

        <s.Container
  
            flex={2}
            jc={"center"}
            ai={"flex-start"}
            style={{
              position:"relative",
              zIndex:999,
              backgroundColor: "#191B1F",
              borderRadius: 20,
        }}> 

                <s.Container style={{padding:10}}>

                <s.Container 
                fd={"row"}
                >
                      <s.TextTitle
                          style={{
                          width: "100%",
                        }}> 
                        Choose your color 
                      </s.TextTitle>

                      <s.Container>
                      <PopperDes />
                      </s.Container>
                      

                </s.Container>
        
                  <s.Container className={"custom-layout"}>
                      <HexColorPicker 
                      //style={{width:"100%",}}
                      color={color} onChange={setColor}
                      />
                  </s.Container>

                  <s.SpacerSmall />

                  <s.TokenIdWrapper style={{position:"relative",}}>
                          <s.TextTokenId> 
                            #{hexToTokenId(color)} 
                          </s.TextTokenId>
                          
                          <s.TextMini> TokenId </s.TextMini>
                  </s.TokenIdWrapper >

                    <s.SpacerXSmall />

                    <s.Container style={{position:"relative",}}>
                      <HexColorInput style={{textTransform:"uppercase",}} color={color} onChange={setColor} />
                      <s.TextMini> Hex # </s.TextMini>
                    </s.Container>

                </s.Container>

                <s.Trenner/>

                <s.Container style={{padding:"8px 10px 0px 10px"}}>
                      
                    <s.Container 
                    fd={"row"}
                    >

                      <s.TextTitle                        
                          style={{
                          width: "100%",
                        }}>
                          Give it a name
                        </s.TextTitle>

                             <PopperName />

                        </s.Container>

                        <s.InputColorName
                            patter={/^\"|\/|\'|\?|\&|\\|\"|\„$/g}
                            value={name}
                            onChange = {nameCalls}
                            style = {{textAlign: "left",}}>
                        </s.InputColorName>

                      <s.TextInfo
                          style={{ width: "100%",}}>
                          1 {CONFIG.SYMBOL} = {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL}
                      </s.TextInfo>
                    

                </s.Container>
            

            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>

                    <s.Container style={{padding:"0px 10px 10px 10px"}}>
                      <s.StyledButton
                      style={{backgroundColor:"#FF4343"}}
                      >
                        The sale has ended 🥲
                      </s.StyledButton>
                    </s.Container>
              </>
            ) : (
              <>
                
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                                   
                  <s.Container style={{padding:"0px 10px 10px 10px"}}>
                    {blockchain.errorMsg !== "" ? (
                        <>

                        <s.StyledButton
                              style={{backgroundColor:"#FF4343"}}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                              }}>  
                              Wrong Network
                        </s.StyledButton>

                        </>
                        ) : 

                        <s.StyledButtonConnectWallet
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}>  
                          Connect Wallet
                        </s.StyledButtonConnectWallet>

                    }
      

                  </s.Container>
                  
                ) : (
                  <>
                              

                        <s.Container style={{padding:"0px 10px 10px 10px"}}>
                        <s.StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "Mint"}
                        </s.StyledButton>
                    </s.Container>

                  </>
                )}
              </>
            )}
         
          </s.Container>

          <s.FeedbackWrapper>    
                        <s.TextFeedback>
                        {feedback}
                        </s.TextFeedback>
                       
                        <s.TextFeedback>
                        {blockchain.errorMsg}
                        </s.TextFeedback>
          </s.FeedbackWrapper>

        </s.ResponsiveWrapper>

                     

                   {/*  <s.TextFeedback
                      style={{
                        textAlign: "center",
                        color: "rgba(255,255,255,0.7)",
                      }}
                    >
                      Feedback: {feedback}
                    </s.TextFeedback> */}

          <s.FooterWrapper>

              <s.Container>
                    <s.TokenIdInfo>
                    #{hexToTokenId(color)}
                    </s.TokenIdInfo> 
              </s.Container>

              <s.Container
              flex={1}
              ai={"flex-end"}
              >
                  <s.ColorInfo>
                    {hexToRgb(color)}
                  </s.ColorInfo> 
              </s.Container>

          </s.FooterWrapper>
          
    </s.Container>

    <s.StyledLogoMobile alt={"logo"} src={"/config/images/logo.png"} />

    <s.Container
        flex={1}
        fd={"row"}
        ai={"center"}
        jc={"center"}>
              <s.MenuWrapperMobile>

                <s.MenuSelected>Mint</s.MenuSelected> 

                <a className="nav-link" target="_blank" href="http://story.crgb.io">
                <s.MenuLink>Story</s.MenuLink> 
                </a>

                <a className="nav-link" target="_blank" href={CONFIG.MARKETPLACE_LINK}>
                <s.MenuLink>Market</s.MenuLink> 
                </a>

                <s.PopperWrapper> <PopperSocial/> </s.PopperWrapper> 

              </s.MenuWrapperMobile>

        </s.Container>

  </s.Screen>
  );
}

export default App;
