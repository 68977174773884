import React, { useEffect, useState, useRef } from "react";
import { usePopper } from 'react-popper';
import "../styles/popper.css";

import { 
    Info, 
    HelpCircle,

 } from 'react-feather';



export default function PopperName() {

const ref = React.createRef();
const [show, setShow] = useState(false);
const [referenceElement, setReferenceElement] = useState(null);
const [popperElement, setPopperElement] = useState(null);
const [arrowElement, setArrowElement] = useState(null);
const { styles, attributes } = usePopper(referenceElement, popperElement, {
  modifiers: [
    { name: "arrow", options: { element: arrowElement } },
    { name: "offset", options: { offset: [0, 8] } }
  ]
});

const handleClickOutside = event => {
  if (ref.current && !ref.current.contains(event.target)) {
    setShow(false);
  }
};

useEffect(() => {
  document.addEventListener("click", handleClickOutside);
  return () => {
    document.removeEventListener("unhandledRejection", handleClickOutside);
  };
});

const toggle = () => setShow(prev => !prev);

return (                          
        <div ref={ref} className="container">
        <Info
        type="button"
        className={"feather"}
        ref={setReferenceElement}
        onClick={toggle}
        />

        {show && (
        <div
            className="tooltip"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
        >
            Color owners are able to set or change the "color name" as they please. Changing the color name will just cost only gas, no extra fees.
            </div>
        )}
        </div>
);
}
