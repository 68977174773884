import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 10px;
  width: 10px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing trenner between components
export const Trenner = styled.div`
  width: 100%;
  // top right bottom left
  margin: 3px 0px 0px 0px;
  border-bottom: 1px solid #2C2F36;
`;


// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  align-content: "space-between";
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;


export const DesktopNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-between;
  //background-color: pink;
  width: 100%;
  //border-bottom: 1px solid #ffffff80;
`;



export const Brand = styled.p`
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

  color: #fff;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.2;
  text-align: center; 
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  font-size: 20px;
  margin: 15px 0px -30px 0px;
  padding: 0px 20px;

  
  @media (min-width: 370px) {
  margin: 20px 0px -20px 0px;
  }

  @media (min-width: 500px) {
    margin: 5px 0px -20px 0px;
    font-size: 27px;
    }
`;



export const ColorInfo = styled.p`
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

  color: #fff;
  letter-spacing: 1px;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;

  @media (min-width: 500px) {
    font-size: 20px;
  }


`;

export const TokenIdInfo = styled.p`
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

  color: #fff;
  letter-spacing: 1px;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;

  @media (min-width: 500px) {
    font-size: 20px;
  }

`;

export const TextTitle  = styled.p`
  color: #fff;
  text-align: left;

  margin: 2px 0px 0px 6px;
  font-size: 13px;

  @media (min-width: 370px) {
    margin: 4px 0px 8px 10px;
    font-size: 14px;
  }

  @media (min-width: 500px) {
    margin: 4px 0px 8px 10px;
    font-size: 15px;
  }

`;


export const Stats = styled.div`
color: #ffffff80;
text-align: left;
font-size: 15px;
letter-spacing: 0.1px;

margin: 10px 0px 15px 5px;
border: 1px solid #2C2F36;
background-color: #191B1F;
border-radius: 12px;
padding: 11px 0px;
height: 39px;
min-width: 120px;

@media (min-width: 370px) {
  
}

:hover{
  border: 1px solid #4A505A;
}
`;



export const TextTokenId  = styled.p`
  color: #ffffff80;
  text-align: center;
  margin: 3px 0px 0px 0px;
  font-size: 13px;

  @media (min-width: 370px) {
    font-size: 14px;
  }

  @media (min-width: 500px) {
    font-size: 16px;
  }
`;

export const TokenIdWrapper = styled.div`
padding: 5px;
border: 1px solid #2C2F36;
border-radius: 6px;
width: 100%;
height: 30px;

@media (min-width: 370px) {
  padding: 8px;
  height: 35px;
}

@media (min-width: 500px) {
  padding: 8px;
  height: 38px;
}
`;


export const TextInfo = styled.p`
  color: #ffffff80;
  text-align: right;
  
  margin: 9px 0px 9px -6px;
  font-size: 13px;

  @media (min-width: 370px) {
    margin: 9px 0px 9px -15px;
    font-size: 14px;
  }

  @media (min-width: 500px) {
    margin: 9px 0px 9px -15px;
    font-size: 15px;
  }

`;

export const TextMini= styled.p`
  color: #ffffff50;
  font-size: 10px;
  position: absolute;
  top: 8px;
  left: 15px;


  @media (min-width: 370px) {
    font-size: 12px;
    top: 10px;
    left: 15px;
  }


`;



export const InputColorName = styled.input`

`;

export const rColorInput = styled.input`

`;

export const gColorInput = styled.input`
`;

export const bColorInput = styled.input`

`;


export const StatsWrapper = styled.div`
display: none;
flex: 1;
margin: 0px 0px 0px 55px;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
@media (min-width: 500px) {
  display: flex;
}

transition: height 0.5s;
}
`;

export const MenuSelected = styled.div`
display: block;
background-color: #2C2F36;
min-width: 50px;
text-align:center;
padding 5px 10px;
border-radius: 10px;

@media (min-width: 500px) {
  border-radius: 12px;
  padding: 8px 15px;
  margin: 0px 0px 0px 0px;
}
`;

export const MenuLink = styled.div`

display: block;
//background-color: #2C2F36;
min-width: 50px;
text-align:center;
padding 5px 15px;
border-radius: 10px;

@media (min-width: 500px) {
  border-radius: 12px;
  padding: 6px 10px;
  margin: 0px 2px;
}
`;

export const PopperWrapper= styled.div`
display: block;
background-color: none;
padding 5px 15px;
border-radius: 10px;
cursor: pointer;

@media (min-width: 500px) {
  border-radius: 12px;
  padding: 6px 10px;
  margin: 0px 2px;
}

`;


export const MenuWrapper = styled.div`
z-index: 99999;
display: none;
justify-content: center;
align-items: center;
color: #AAAAAA;
font-size: 15px;
letter-spacing: 0.1px;
margin: 10px 15px 15px 10px;
background-color: #191B1F;
border-radius: 15px;
padding: 10px 8px 10px 4px;
height: 39px;



@media (min-width: 500px) {
display: flex;
padding: 10px 8px 10px 4px;
}

`;

export const MenuWrapperMobile = styled.div`
z-index: 99999;
position: fixed;
bottom: 10px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;

padding: 0px 5px 0px 5px;
border-radius: 20px;

color: #AAAAAA;
font-size: 15px;
letter-spacing: 0.1px;
background-color: #191B1F;

height: 35px;
width: 300px;


@media (min-width: 370px) {
  
  height: 35px;
  right: 15px;
}

@media (min-width: 500px) {
  display: none;
}
`;

export const TextFeedback= styled.p`
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

  color: #ffffff;
  line-height: 1.5;
  text-align: center;
  font-size: 12px;

  @media (min-width: 370px) {
    font-size: 13px;
  }
  

  @media (min-width: 500px) {
    font-size: 15px;
  }
  
`;


export const FeedbackWrapper= styled.div`

display: felx;
border-radius: 0px 0px 15px 15px;
z-index: 0;
text-shadow: 0 0 3px #000000;
align-items: center;
justify-content: center;
position: relative;
height: 50px;
padding: 13px 0px 0px 0px;
top: -15px;
z-index: 0;

  @media (min-width: 500px) {
    padding: 25px 0px 0px 0px;
  }
`;


export const StyledButton = styled.button`
  font-family: "Helvetica", sans-serif;
  border: none;
  background-color: #2875E2;
  font-weight: regular;
  color: #fff;
  width: 100%;
  cursor: pointer;

  border-radius: 10px;
  padding: 6px;
  height: 30px;
  font-size: 13px;

  :hover {
    background-color: #418FF5;
  }

  @media (min-width: 370px) {
    padding: 6px;
    border-radius: 10px;
    height: 35px;
    font-size: 14px;
  }

  @media (min-width: 500px) {
    padding: 8px;
    border-radius: 10px;
    height: 38px;
    font-size: 16px;
  }

`;

export const StyledButtonConnectWallet = styled.button`
  font-family: "Helvetica", sans-serif;
  border: 0px;
  background-color: #DEDEDE;
  font-weight: regular;
  color: #000;
  width: 100%;
  cursor: pointer;

  border-radius: 10px;
  padding: 6px;
  height: 30px;
  font-size: 13px;
  
  :hover {
    background-color: #FFF;
  }

  @media (min-width: 370px) {
    padding: 6px;
    border-radius: 10px;
    height: 35px;
    font-size: 14px;
  }

  @media (min-width: 500px) {
    padding: 8px;
    border-radius: 10px;
    height: 38px;
    font-size: 16px;
  }

`;


export const ResponsiveWrapper = styled.div`
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px 30px;

  @media (min-width: 370px) {
    width: 100%;
    max-width: 400px;
    padding: 0px 30px 0px 30px;
    margin: 40px 0px 0px 0px;
  }

`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  :hover {
    color: rgba(255,255,255,.5);
  }
`;

export const StyledLogo = styled.img`
  position: fixed;
  display: none;
  width: 32px;
  margin: 13px 10px 0px 15px;
  
  @media (min-width: 500px) {
    display: block;
  }

  transition: width 0.5s;
  transition: height 0.5s;
`;


export const StyledLogoMobile = styled.img`
  position: fixed;
  bottom: 12px;
  left: 15px;
  width: 32px;
  display: none;

  @media (min-width: 370px) {
    display: block;
    }

  @media (min-width: 500px) {
  display: none;
  }

  transition: width 0.5s;
  transition: height 0.5s;
`;


export const FooterWrapper = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  align-items: flex-end;
  //background-color: green;
  bottom: 55px;
  mix-blend-mode: difference;
  padding: 0px 20px 0px 20px;
 
  @media (min-width: 370px) {
    bottom: 55px;
  }

  @media (min-width: 500px) {
    bottom: 0px;
    padding: 0px 20px 10px 20px;
  }
`;


