import React, { useEffect, useState, useRef } from "react";
import { usePopper } from 'react-popper';
import "../styles/popper.css";

import { 
    Info, 
    HelpCircle,
    ArrowUpRight,

 } from 'react-feather';



export default function PopperDes() {

const ref = React.createRef();
const [show, setShow] = useState(false);
const [referenceElement, setReferenceElement] = useState(null);
const [popperElement, setPopperElement] = useState(null);
const [arrowElement, setArrowElement] = useState(null);
const { styles, attributes } = usePopper(referenceElement, popperElement, {
  modifiers: [
    { name: "arrow", options: { element: arrowElement } },
    { name: "offset", options: { offset: [0, 8] } }
  ]
});

const handleClickOutside = event => {
  if (ref.current && !ref.current.contains(event.target)) {
    setShow(false);
  }
};

useEffect(() => {
  document.addEventListener("click", handleClickOutside);
  return () => {
    document.removeEventListener("unhandledRejection", handleClickOutside);
  };
});

const toggle = () => setShow(prev => !prev);

return (                          
        <div ref={ref} className="container">
        <HelpCircle
        type="button"
        className={"feather"}
        ref={setReferenceElement}
        onClick={toggle}
        />

        {show && (
        <div
            className="tooltip"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
        >
            cRGB is the first on-chain NFT series that is based entirely on sRGB values. It is limited to a total of 16,777,216 pieces, which is the maximum number of sRGB value combinations.
            <div className="same-line-wrapper">
            <div className="tooltiplink" ><a href="https://crgb.medium.com/introducing-crgb-the-decentralized-color-space-3308f77dcee8" target="_blank" >Medium<ArrowUpRight  className={"feather-arrow"}/></a></div>
            <div className="tooltiplink" ><a href="https://etherscan.io/address/0x8ee7109d7846c3dbda2f4f7d3690fb9f3e19397d" target="_blank" >Contract<ArrowUpRight  className={"feather-arrow"}/></a></div>
            </div>
        </div>
        )}
        </div>
);
}
